/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 2rem 0.5rem 1rem 0.5rem;
  background-color: $color-white;
  z-index: 2;
  height: 138px;

  @include bp(large) {
    height: rem(168);
    padding: 2rem;
  }
}

.c-header__inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @include bp(large) {
    flex-direction: row;
    align-items: stretch;
  }
}

.c-header__logo {
  margin-bottom: 2.5rem;
  width: rem(140);
  display: flex;
  align-items: center;
  justify-content: center;

  @include bp(large) {
    width: rem(174);
    margin-bottom: 0;
  }
}

.c-header__skip-to-content {
  position: absolute;
  left: -9999rem;
  top: rem(7px);
  font-weight: 600;
  clip: rect(1px 1px 1px 1px);
  border-radius: rem(4px);
  padding: rem(10px) rem(15px);
  z-index: 9999999;
  border: 0;
  font-size: rem(18px);
  text-decoration: none;

  &:focus {
    clip: auto;
    left: rem(7);
    box-shadow: 0 0 0 7px $color-green;
  }
}
