.o-grid {
  display: grid;
  gap: 2rem;
}

.o-grid--col-1 {
  grid-template-columns: 1fr;
}

@include bp(large) {
  .o-lg-grid--col-2 {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
}
