/* ==========================================================================
   #TEZT COLORS
   ========================================================================== */

.u-color-green {
  color: $color-green;
}

.u-color-white {
  color: $color-white;
}

.u-bg-dark-green {
  background-color: $color-darker-green;
}

.u-bg-medium-green {
  background-color: $color-dark-green;
}

.u-bg-gradient {
  background: linear-gradient(
    90deg,
    rgba(15, 67, 65, 1) 0%,
    rgba(43, 177, 167, 1) 100%
  );
}
