/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: rem(1240);
  position: relative;
}

.o-wrapper--lg {
  max-width: 1740px;
}

.o-wrapper--md {
  max-width: 1430px;
}
