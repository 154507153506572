.c-hero {
  background-image: url('~assets/images/vensights-hero-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 6.5rem 0;
  border-top: 13px solid $color-green;

  @include bp(large) {
    padding: 8.5rem 0;
  }

  @include bp(xlarge) {
    padding: 12rem 0;
  }

  @include bp(full) {
    padding: 19rem 0;
  }
}
