@charset "UTF-8";
/*
  Project: Vensights
  Author: xFive
 */
/* Fonts
   ========================================================================== */
/* Breakpoints
   ========================================================================== */
/* Colors
   ========================================================================== */
/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */
/**
 * More sensible default box-sizing:
 * css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
 */
html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

/* ==========================================================================
   #FONT-FACE
   ========================================================================== */
/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */
/* ==========================================================================
   #NORMALIZE
   ========================================================================== */
@import-normalize;
/* ==========================================================================
   #RESET
   ========================================================================== */
/**
 * A very simple reset that sits on top of Normalize.css.
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
dd,
ol,
ul,
figure,
hr,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

/**
 * Remove trailing margins from nested lists.
 */
li > ol,
li > ul {
  margin-bottom: 0;
}

/**
 * Remove default table spacing.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
 *    on fieldsets.
 */
fieldset {
  min-width: 0; /* [1] */
  border: 0;
}

/* ==========================================================================
   #SHARED
   ========================================================================== */
/**
 * Shared declarations for certain elements.
 */
/**
 * Always declare margins in the same direction:
 * csswizardry.com/2012/06/single-direction-margin-declarations
 */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: 1.3rem;
}

/**
 * Consistent indentation for lists.
 */
dd,
ol,
ul {
  margin-left: 1rem;
}

/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */
blockquote {
  quotes: "“" "”" "‘" "’";
  font-style: italic;
}
blockquote p:first-child::before {
  content: open-quote;
}
blockquote p:last-child::after {
  content: close-quote;
}

/* ==========================================================================
   #HEADINGS
   ========================================================================== */
/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */
h1,
.e-h1 {
  font-size: 14vw;
  font-weight: 600;
}
@media (min-width: 1024px) {
  h1,
.e-h1 {
    font-size: 10rem;
  }
}

h2,
.e-h2 {
  font-size: 3rem;
  font-weight: 600;
}
@media (min-width: 1024px) {
  h2,
.e-h2 {
    font-size: 6.125rem;
  }
}

h3,
.e-h3 {
  font-size: 2.5rem;
  font-weight: 700;
}
@media (min-width: 1024px) {
  h3,
.e-h3 {
    font-size: 3.5rem;
  }
}

h4,
.e-h4 {
  font-size: 1.75rem;
  font-weight: 700;
}
@media (min-width: 1024px) {
  h4,
.e-h4 {
    font-size: 1.875rem;
  }
}

h5,
.e-h5 {
  font-size: 1.5rem;
  font-weight: 600;
}
@media (min-width: 1024px) {
  h5,
.e-h5 {
    font-size: 1.875rem;
  }
}

h6,
.e-h6 {
  font-size: 1rem;
  font-weight: 600;
}

h1,
.e-h1,
h2,
.e-h2 {
  line-height: 1em;
}

h3,
.e-h3,
h4,
.e-h4,
h5,
.e-h5,
h6,
.e-h6 {
  line-height: 1.2em;
}

/* ==========================================================================
   #HR
   ========================================================================== */
hr {
  display: block;
}

/* ==========================================================================
   #PAGE
   ========================================================================== */
/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
* {
  box-sizing: border-box;
}

html {
  background: #fff;
  color: #000;
  font-size: 13px; /* [1] */ /* 13px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
@media (min-width: 768px) {
  html {
    font-size: 14px; /* 14px */
  }
}
@media (min-width: 1024px) {
  html {
    font-size: 15px; /* 15px */
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 16px; /* 16px */
  }
}

html,
body,
button,
input,
textarea {
  font-weight: 400;
  font-family: "Poppins", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", "Arial", sans-serif;
}

/* ==========================================================================
   #IMAGES
   ========================================================================== */
/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */
img {
  font-style: italic; /* [2] */
  max-width: 100%; /* [1] */
  vertical-align: middle; /* [3] */
}

/**
 * 1. If a `width` and/or `height` attribute have been explicitly defined, let’s
 *    not make the image fluid.
 */
img[width],
img[height] {
  max-width: none;
}

/* ==========================================================================
   #LINKS
   ========================================================================== */
a {
  color: #666;
  text-decoration: underline;
}
a:hover {
  color: #999;
}

/* ==========================================================================
   #LISTS
   ========================================================================== */
li {
  margin-bottom: 0.5rem;
}
li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1rem;
  margin-top: 0.5rem;
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1rem;
  margin-left: 1rem;
}

p {
  font-size: 1.3125rem;
  line-height: 1.8;
}

/* ==========================================================================
   #TABLES
   ========================================================================== */
/**
 * 1. Ensure tables fill up as much space as possible.
 */
table {
  width: 100%; /* [1] */
}

/**
 * Note: I have omitted any vendor-prefixes for clarity.
 * Adding them is left as an exercise for the reader.
 */
.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
  box-shadow: 0 0.625rem 0.625rem rgba(0, 0, 0, 0.1);
}

.headroom--unpinned {
  transform: translateY(-100%);
}

.o-grid {
  display: grid;
  gap: 2rem;
}

.o-grid--col-1 {
  grid-template-columns: 1fr;
}

@media (min-width: 1024px) {
  .o-lg-grid--col-2 {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
}
/* ==========================================================================
   #LAYOUT
   ========================================================================== */
/* Simple flexbox layout system */
.o-layout {
  display: block;
}
@media (min-width: 768px) {
  .o-layout {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}
.o-layout--guttered > .o-layout__item {
  flex: none;
}

@media (min-width: 768px) {
  .o-layout--2 > .o-layout__item {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .o-layout--3 > .o-layout__item {
    width: 32%;
  }
}

@media (min-width: 768px) {
  .o-layout--4 > .o-layout__item {
    width: 24%;
  }
}

/* ==========================================================================
   #LIST-BARE
   ========================================================================== */
/**
 * Strip list-like appearance from lists by removing their bullets, and any
 * indentation.
 */
.o-list-bare {
  list-style: none;
  margin-left: 0;
}

/* ==========================================================================
   #LIST-INLINE
   ========================================================================== */
/**
 * The list-inline object simply displays a list of items in one line.
 */
.o-list-inline {
  list-style: none;
  margin-left: 0;
}

.o-list-inline__item {
  display: inline-block;
}

/* ==========================================================================
   #MEDIA
   ========================================================================== */
/**
 * Place any image- and text-like content side-by-side, as per:
 * http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code
 */
.o-media {
  display: block;
}
.o-media::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-media__img {
  float: left;
  margin-right: 1rem;
}
.o-media__img > img {
  display: block;
}

.o-media__body {
  display: block;
  overflow: hidden;
}
.o-media__body,
.o-media__body > :last-child {
  margin-bottom: 0;
}

/* Reversed media objects
   ========================================================================== */
.o-media--reverse > .o-media__img {
  float: right;
  margin-left: 1rem;
  margin-right: 0;
}

/* Gutterless media objects
   ========================================================================== */
.o-media--flush > .o-media__img {
  margin-left: 0;
  margin-right: 0;
}

/* ==========================================================================
   #TABLE
   ========================================================================== */
/**
 * A simple object for manipulating the structure of HTML `table`s.
 */
.o-table {
  width: 100%;
}

/* Equal-width table cells.
   ========================================================================== */
/**
 * `table-layout: fixed` forces all cells within a table to occupy the same
 * width as each other. This also has performance benefits: because the browser
 * does not need to (re)calculate cell dimensions based on content it discovers,
 * the table can be rendered very quickly. Further reading:
 * https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#Values
 */
.o-table--fixed {
  table-layout: fixed;
}

/* ==========================================================================
   #WRAPPER
   ========================================================================== */
/**
 * Page-level constraining and wrapping elements.
 */
.o-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 77.5rem;
  position: relative;
}
.o-wrapper::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

.o-wrapper--lg {
  max-width: 1740px;
}

.o-wrapper--md {
  max-width: 1430px;
}

/* ==========================================================================
   #BUTTONS
   ========================================================================== */
/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */
/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */
.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  border: 0;
  padding: 0.5rem 1rem;
  transition: all 300ms ease-in-out;
  border-radius: 3px;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;
}
.c-btn:hover, .c-btn:active, .c-btn:focus {
  text-decoration: none; /* [4] */
}

/* Style variants
   ========================================================================== */
.c-btn--primary {
  background-color: #666;
}
.c-btn--primary:hover, .c-btn--primary:active, .c-btn--primary:focus {
  color: #fff;
  background-color: #999;
}

/* Size variants
   ========================================================================== */
.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
}

.c-btn--full {
  width: 100%;
}

.c-btn--green {
  background: #2bb1a7;
  color: #fff;
}

.c-btn--dark-green {
  background-color: #0f4341;
  color: #fff;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: 0.125rem 0.125rem 0.3125rem rgba(0, 0, 0, 0.5);
}
.c-btn--dark-green:hover, .c-btn--dark-green:focus, .c-btn--dark-green:active {
  background-color: #2bb1a7;
  box-shadow: 0.1875rem 0.1875rem 0.625rem rgba(0, 0, 0, 0.5);
}
.c-btn--dark-green:focus {
  outline: 0;
  box-shadow: 0.1875rem 0.1875rem 0.625rem rgba(0, 0, 0, 0.5), 0 0 0 0.25rem #0f4341;
}

.c-btn--cta {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: #fff;
  font-style: italic;
  font-size: 1.875rem;
  border-radius: 9999px;
  line-height: 1.1;
  background: linear-gradient(90deg, rgb(15, 67, 65) 0%, rgb(43, 177, 167) 100%);
  transition: box-shadow 0.3s ease-in-out;
}
.c-btn--cta:hover, .c-btn--cta:active, .c-btn--cta:focus {
  color: #fff;
  box-shadow: 0.1875rem 0.1875rem 0.625rem rgba(0, 0, 0, 0.5);
}
@media (min-width: 1024px) {
  .c-btn--cta {
    font-size: 2.9375rem;
  }
}

.c-card {
  max-width: 32.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-card__image {
  display: block;
  max-width: 14.25rem;
  width: 40%;
  margin-bottom: 0.625rem;
}
@media (min-width: 1024px) {
  .c-card__image {
    width: 100%;
  }
}

.c-card__title {
  margin-bottom: 1.875rem;
}

/* ==========================================================================
   #FOOTER
   ========================================================================== */
.c-footer {
  font-size: 0.875rem;
  padding-top: 1em;
}

.c-footer__logo {
  display: inline-block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 40%;
}
@media (min-width: 1024px) {
  .c-footer__logo {
    margin-top: 10rem;
    width: auto;
  }
}

@media (min-width: 1024px) {
  .c-footer__menu-container {
    margin-left: 1.25rem;
  }
}

.c-footer__contact-item {
  display: flex;
  align-items: flex-start;
}

.c-footer__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 0.5rem;
  background-color: #fff;
  border-radius: 24px;
}
@media (min-width: 1024px) {
  .c-footer__icon {
    margin-top: 0.4rem;
  }
}

.c-footer__icon-svg {
  fill: #0f4341;
  width: 20px;
}

.c-footer__constact-link {
  text-decoration: none;
  font-weight: 500;
  font-size: 1.25rem;
}
@media (min-width: 1024px) {
  .c-footer__constact-link {
    font-size: 1.5625rem;
  }
}

.c-footer__menu {
  list-style-type: none;
  margin-left: 0;
}
.c-footer__menu li a {
  color: #fff;
}

.c-footer__colophon {
  margin-bottom: 0;
}

form {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1024px) {
  .c-form {
    padding-right: 3.75rem;
  }
}

.c-form__control {
  margin-bottom: 1rem;
}

.c-form__label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  display: block;
}

.c-form__field {
  display: block;
  width: 100%;
  height: 3.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.125rem;
  border: 0;
  font-size: 1.2rem;
  appearance: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s ease-in-out;
}
.c-form__field:focus {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15), 0 0 0 4px #0f4341 !important;
  outline: 0;
}

.c-form__field-textarea {
  min-height: 12.5rem;
}

/* ==========================================================================
   #HEADER
   ========================================================================== */
.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 2rem 0.5rem 1rem 0.5rem;
  background-color: #fff;
  z-index: 2;
  height: 138px;
}
@media (min-width: 1024px) {
  .c-header {
    height: 10.5rem;
    padding: 2rem;
  }
}

.c-header__inner {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .c-header__inner {
    flex-direction: row;
    align-items: stretch;
  }
}

.c-header__logo {
  margin-bottom: 2.5rem;
  width: 8.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 1024px) {
  .c-header__logo {
    width: 10.875rem;
    margin-bottom: 0;
  }
}

.c-header__skip-to-content {
  position: absolute;
  left: -9999rem;
  top: 0.4375rem;
  font-weight: 600;
  clip: rect(1px 1px 1px 1px);
  border-radius: 0.25rem;
  padding: 0.625rem 0.9375rem;
  z-index: 9999999;
  border: 0;
  font-size: 1.125rem;
  text-decoration: none;
}
.c-header__skip-to-content:focus {
  clip: auto;
  left: 0.4375rem;
  box-shadow: 0 0 0 7px #2bb1a7;
}

.c-hero {
  background-image: url("~assets/images/vensights-hero-image.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 6.5rem 0;
  border-top: 13px solid #2bb1a7;
}
@media (min-width: 1024px) {
  .c-hero {
    padding: 8.5rem 0;
  }
}
@media (min-width: 1280px) {
  .c-hero {
    padding: 12rem 0;
  }
}
@media (min-width: 1600px) {
  .c-hero {
    padding: 19rem 0;
  }
}

.c-initial-content__title {
  margin-bottom: 2rem;
}

.c-main {
  padding-top: 138px;
}
@media (min-width: 1024px) {
  .c-main {
    padding-top: 10.5rem;
  }
}

.c-menu {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  list-style-type: none;
  flex-wrap: wrap;
}
@media (min-width: 1024px) {
  .c-menu {
    position: relative;
    top: 0.625rem;
  }
}
@media (min-width: 1860px) {
  .c-menu {
    left: 3.125rem;
  }
}

.c-menu__item {
  margin-bottom: 0;
}

.c-menu__item + .c-menu__item {
  border-left: 1px solid #007e71;
}

.c-menu__link {
  padding: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #007e71;
  transition: color 0.3s ease-in-out;
  font-weight: 500;
}
.c-menu__link:hover {
  color: #0f4341;
}
@media (min-width: 1024px) {
  .c-menu__link {
    padding: 0.5rem 1rem;
    font-size: 1.1875rem;
  }
}

.c-section {
  padding: 2rem;
  position: relative;
}

.c-section--gradient {
  background: linear-gradient(90deg, rgb(15, 67, 65) 0%, rgb(43, 177, 167) 100%);
}

.c-section--pattern::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url("~assets/images/pattern.svg");
  background-size: 144px;
  opacity: 0.1;
}

.c-section--initial-content {
  padding: 3rem 2rem 4rem 2rem;
}

.c-section--what-we-do {
  padding: 4rem 2rem 2.5rem 2rem;
}

.c-section--services {
  padding: 2rem 2rem 4.6rem 2rem;
  border-bottom: 18px solid #2bb1a7;
}

@media (min-width: 1024px) {
  .c-section--who-we-are {
    padding: 5.2rem 2rem 5.2rem 2rem;
  }
}

@media (min-width: 1024px) {
  .c-section--footer {
    padding: 5.2rem 2rem 5.2rem 2rem;
  }
}

.c-what-we-do__title {
  margin-bottom: 3.2rem;
}

.c-who-we-are__title {
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .c-who-we-are__title {
    margin-bottom: 5.2rem;
  }
}

.c-who-we-are__subheading {
  margin-top: 3.125rem;
  margin-bottom: 3.75rem;
}

.c-who-we-are__avatar {
  width: 17.875rem;
  border-radius: 9999px;
  overflow: hidden;
  flex: 0 0 auto;
  margin-bottom: 2rem;
}
@media (min-width: 1024px) {
  .c-who-we-are__avatar {
    margin-right: 2rem;
  }
}

.c-who-we-are__our-founder-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
@media (min-width: 1024px) {
  .c-who-we-are__our-founder-section {
    flex-direction: row;
    margin-bottom: 6rem;
  }
}

.c-who-we-are__our-values-heading {
  margin-bottom: 2.1875rem;
}
@media (min-width: 1024px) {
  .c-who-we-are__our-values-heading {
    text-align: center;
    margin-top: 3.125rem;
    margin-bottom: 3.75rem;
  }
}

.c-who-we-are__our-founder-heading {
  margin-top: 5.75rem;
  margin-bottom: 3.125rem;
}

.c-who-we-are__our-values-subtitle {
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.c-who-we-are__cta-title {
  padding-top: 1.875rem;
  font-size: 2.375rem;
}
@media (min-width: 1024px) {
  .c-who-we-are__cta-title {
    padding-top: 3.75rem;
  }
}

@media (min-width: 1024px) {
  .c-who-we-are__values-card--left-offset {
    padding-left: 2.5rem;
  }
}
@media (min-width: 1280px) {
  .c-who-we-are__values-card--left-offset {
    padding-left: 5rem;
  }
}

/* ==========================================================================
   #ALIGN
   ========================================================================== */
.u-float-left {
  float: left;
}

.u-float-right {
  float: right;
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

/* ==========================================================================
   #CLEARFIX
   ========================================================================== */
/**
 * Attach our clearfix mixin to a utility class.
 */
.u-clearfix::after {
  content: "" !important;
  display: block !important;
  clear: both !important;
}

/* ==========================================================================
   #TEZT COLORS
   ========================================================================== */
.u-color-green {
  color: #2bb1a7;
}

.u-color-white {
  color: #fff;
}

.u-bg-dark-green {
  background-color: #0f4341;
}

.u-bg-medium-green {
  background-color: #184e4b;
}

.u-bg-gradient {
  background: linear-gradient(90deg, rgb(15, 67, 65) 0%, rgb(43, 177, 167) 100%);
}

.u-font-regular {
  font-weight: 400;
}

.u-font-medium {
  font-weight: 500;
}

.u-font-semibold {
  font-weight: 600;
}

.u-font-bold {
  font-weight: 700;
}

.u-font-xs {
  font-size: 0.875rem;
}

.u-font-sm {
  font-size: 1rem;
}

.u-font-md {
  font-size: 1.2rem;
}
@media (min-width: 1024px) {
  .u-font-md {
    font-size: 1.3125rem;
  }
}

.u-font-lg {
  font-size: 1.5rem;
  line-height: 1.7em;
}
@media (min-width: 1024px) {
  .u-font-lg {
    font-size: 1.6875rem;
  }
}

.u-uppercase {
  text-transform: uppercase;
}

/* ==========================================================================
   #HIDE
   ========================================================================== */
/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * Hide visually and from screen readers.
 */
.u-hidden {
  display: none !important;
}

.u-hidden\@small {
  display: none;
}
@media (min-width: 768px) {
  .u-hidden\@small {
    display: block;
  }
}

.u-full-height {
  height: 100%;
}