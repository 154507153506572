/**
 * Note: I have omitted any vendor-prefixes for clarity.
 * Adding them is left as an exercise for the reader.
 */
.headroom {
  will-change: transform;
  transition: transform 200ms linear;
}

.headroom--pinned {
  transform: translateY(0%);
  box-shadow: 0 rem(10) rem(10) rgba(0, 0, 0, 0.1);
}

.headroom--unpinned {
  transform: translateY(-100%);
}
