.u-font-regular {
  font-weight: 400;
}

.u-font-medium {
  font-weight: 500;
}

.u-font-semibold {
  font-weight: 600;
}

.u-font-bold {
  font-weight: 700;
}

.u-font-xs {
  font-size: rem(14);
}

.u-font-sm {
  font-size: 1rem;
}

.u-font-md {
  font-size: 1.2rem;

  @include bp(large) {
    font-size: 1.3125rem;
  }
}

.u-font-lg {
  font-size: 1.5rem;
  line-height: 1.7em;

  @include bp(large) {
    font-size: 1.6875rem;
  }
}

.u-uppercase {
  text-transform: uppercase;
}
