.c-card {
  max-width: 32.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.c-card__image {
  display: block;
  max-width: rem(228);
  width: 40%;
  margin-bottom: rem(10);

  @include bp(large) {
    width: 100%;
  }
}

.c-card__title {
  margin-bottom: rem(30);
}
