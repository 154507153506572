/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-btn {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  border: 0;
  padding: 0.5rem 1rem;
  transition: $global-transition;
  border-radius: $global-radius;
  text-decoration: none;
  font-weight: 700;
  text-transform: uppercase;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none; /* [4] */
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &:hover,
  &:active,
  &:focus {
    color: $color-white;
    background-color: $color-hover;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--small {
  padding: 0.25rem 0.5rem;
}

.c-btn--large {
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
}

.c-btn--full {
  width: 100%;
}

.c-btn--green {
  background: $color-green;
  color: $color-white;
}

.c-btn--dark-green {
  background-color: $color-darker-green;
  color: $color-white;
  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: rem(2) rem(2) rem(5) rgba(0, 0, 0, 0.5);

  &:hover,
  &:focus,
  &:active {
    background-color: $color-green;
    box-shadow: rem(3) rem(3) rem(10) rgba(0, 0, 0, 0.5);
  }

  &:focus {
    outline: 0;
    box-shadow: rem(3) rem(3) rem(10) rgba(0, 0, 0, 0.5),
      0 0 0 rem(4) $color-darker-green;
  }
}

.c-btn--cta {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  color: $color-white;
  font-style: italic;
  font-size: rem(30);
  border-radius: 9999px;
  line-height: 1.1;
  background: linear-gradient(
    90deg,
    rgba(15, 67, 65, 1) 0%,
    rgba(43, 177, 167, 1) 100%
  );
  transition: box-shadow 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: $color-white;
    box-shadow: rem(3) rem(3) rem(10) rgba(0, 0, 0, 0.5);
  }

  @include bp(large) {
    font-size: rem(47);
  }
}
