.c-menu {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  list-style-type: none;
  flex-wrap: wrap;

  @include bp(large) {
    position: relative;
    top: rem(10);
  }

  @include bp(1860px) {
    left: rem(50);
  }
}

.c-menu__item {
  margin-bottom: 0;
}

.c-menu__item + .c-menu__item {
  border-left: 1px solid $color-medium-green;
}

.c-menu__link {
  padding: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-medium-green;
  transition: color 0.3s ease-in-out;
  font-weight: 500;

  &:hover {
    color: $color-darker-green;
  }

  @include bp(large) {
    padding: 0.5rem 1rem;
    font-size: rem(19);
  }
}
