/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  font-size: 0.875rem;
  padding-top: 1em;
}

.c-footer__logo {
  display: inline-block;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 40%;

  @include bp(large) {
    margin-top: 10rem;
    width: auto;
  }
}

.c-footer__menu-container {
  @include bp(large) {
    margin-left: rem(20);
  }
}

.c-footer__contact-item {
  display: flex;
  align-items: flex-start;
}

.c-footer__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 0.5rem;
  background-color: $color-white;
  border-radius: 24px;

  @include bp(large) {
    margin-top: 0.4rem;
  }
}

.c-footer__icon-svg {
  fill: $color-darker-green;
  width: 20px;
}

.c-footer__constact-link {
  text-decoration: none;
  font-weight: 500;
  font-size: rem(20);

  @include bp(large) {
    font-size: rem(25);
  }
}

.c-footer__menu {
  list-style-type: none;
  margin-left: 0;

  li {
    a {
      color: $color-white;
    }
  }
}

.c-footer__colophon {
  margin-bottom: 0;
}

form {
  display: flex;
  flex-direction: column;
}
