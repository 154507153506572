.c-who-we-are__title {
  margin-bottom: 3rem;

  @include bp(large) {
    margin-bottom: 5.2rem;
  }
}

.c-who-we-are__subheading {
  margin-top: rem(50);
  margin-bottom: rem(60);
}

.c-who-we-are__avatar {
  width: rem(286);
  border-radius: 9999px;
  overflow: hidden;
  flex: 0 0 auto;
  margin-bottom: 2rem;

  @include bp(large) {
    margin-right: 2rem;
  }
}

.c-who-we-are__our-founder-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;

  @include bp(large) {
    flex-direction: row;
    margin-bottom: 6rem;
  }
}

.c-who-we-are__our-values-heading {
  margin-bottom: rem(35);

  @include bp(large) {
    text-align: center;
    margin-top: rem(50);
    margin-bottom: rem(60);
  }
}

.c-who-we-are__our-founder-heading {
  margin-top: rem(92);
  margin-bottom: rem(50);
}

.c-who-we-are__our-values-subtitle {
  margin-bottom: 0.3rem;
  font-weight: 600;
}

.c-who-we-are__cta-title {
  padding-top: rem(30);
  font-size: rem(38);

  @include bp(large) {
    padding-top: rem(60);
  }
}

.c-who-we-are__values-card--left-offset {
  @include bp(large) {
    padding-left: rem(40);
  }

  @include bp(xlarge) {
    padding-left: rem(80);
  }
}
