/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */
* {
  box-sizing: border-box;
}

html {
  background: $color-bg;
  color: $color-text;
  font-size: 13px; /* [1] */ /* 13px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  overflow-y: scroll; /* [2] */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  @include bp(medium) {
    font-size: 14px; /* 14px */
  }

  @include bp(large) {
    font-size: 15px; /* 15px */
  }

  @include bp(full) {
    font-size: 16px; /* 16px */
  }
}

html,
body,
button,
input,
textarea {
  font-weight: 400;
  font-family: $font-sans-serif;
}
