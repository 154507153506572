.c-section {
  padding: 2rem;
  position: relative;
}

.c-section--gradient {
  background: linear-gradient(
    90deg,
    rgba(15, 67, 65, 1) 0%,
    rgba(43, 177, 167, 1) 100%
  );
}

.c-section--pattern {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('~assets/images/pattern.svg');
    background-size: 144px;
    opacity: 0.1;
  }
}

.c-section--initial-content {
  padding: 3rem 2rem 4rem 2rem;
}

.c-section--what-we-do {
  padding: 4rem 2rem 2.5rem 2rem;
}

.c-section--services {
  padding: 2rem 2rem 4.6rem 2rem;
  border-bottom: 18px solid $color-green;
}

.c-section--who-we-are {
  @include bp(large) {
    padding: 5.2rem 2rem 5.2rem 2rem;
  }
}

.c-section--footer {
  @include bp(large) {
    padding: 5.2rem 2rem 5.2rem 2rem;
  }
}
