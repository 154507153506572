/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1,
.e-h1 {
  font-size: 14vw;
  font-weight: 600;

  @include bp(large) {
    font-size: 10rem;
  }
}

h2,
.e-h2 {
  font-size: 3rem;
  font-weight: 600;

  @include bp(large) {
    font-size: 6.125rem;
  }
}

h3,
.e-h3 {
  font-size: 2.5rem;
  font-weight: 700;

  @include bp(large) {
    font-size: 3.5rem;
  }
}

h4,
.e-h4 {
  font-size: rem(28);
  font-weight: 700;

  @include bp(large) {
    font-size: rem(30);
  }
}

h5,
.e-h5 {
  font-size: 1.5rem;
  font-weight: 600;

  @include bp(large) {
    font-size: 1.875rem;
  }
}

h6,
.e-h6 {
  font-size: 1rem;
  font-weight: 600;
}

h1,
.e-h1,
h2,
.e-h2 {
  line-height: 1em;
}

h3,
.e-h3,
h4,
.e-h4,
h5,
.e-h5,
h6,
.e-h6 {
  line-height: 1.2em;
}
