.c-form {
  @include bp(large) {
    padding-right: rem(60);
  }
}

.c-form__control {
  margin-bottom: 1rem;
}

.c-form__label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  display: block;
}

.c-form__field {
  display: block;
  width: 100%;
  height: rem(52);
  padding: rem(6) rem(12);
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.125rem;
  border: 0;
  font-size: 1.2rem;
  appearance: none;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  transition: box-shadow 0.2s ease-in-out;

  &:focus {
    box-shadow: 0 0 10px rgb(0 0 0 / 15%), 0 0 0 4px $color-darker-green !important;
    outline: 0;
  }
}

.c-form__field-textarea {
  min-height: rem(200);
}
